// 2. Import default variables and available mixins.
@import 'uikit/src/scss/variables-theme.scss';
@import 'uikit/src/scss/mixins-theme.scss';

// 4. Import UIkit.
@import 'uikit/src/scss/uikit-theme.scss';

html,
body {
    margin: 0;
    padding: 0;
}
